.document-card {
  position: relative;
  padding: 10px;
  cursor: pointer;
  width: 200px; /* Set a fixed width for all document cards */
}

.document-card .document-preview {
  position: relative;
}

.document-card .document-preview img {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  transition: opacity 0.3s ease-in-out;
}

.document-card .document-preview .file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 5px;
  font-size: 48px;
  /* color: #f1c40f; Yellow shade for file icon */
}

.document-card:hover img {
  opacity: 0.4;
}

.document-card .file-name {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

.document-card .options {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.document-card:hover .options {
  opacity: 1;
}

.document-card .download-icon,
.document-card .delete-icon,
.document-card .revise-icon

{
  font-size: 18px;
  color: #007bff;
  margin-top: 60px;
  margin-left: 8px;
}

.document-card .delete-icon {
  color: #dc3545;
}

.document-card .revise-icon {
  color: #059c00;
}

.file-icon {
  background-color: #ebebeb;
}
