.plantsList {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.dialogBox {
  background: #ffffff;
  min-width: 40vw;
  padding: 1% 5%;
  box-shadow: 3px 4px 11px 1px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 3px 4px 11px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 3px 4px 11px 1px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  min-height: 200px;
}

.plantCard {
  margin: 0% 2%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.plantCard:hover {
  cursor: pointer;
  background: #dbdbdb;
}

.plantName {
  margin: 0px 5px;
  font-family: "Inter", sans-serif;
}

.plantCode {
  margin: 0px 5px;
  font-size: 12px;
  opacity: 0.8;
  font-family: "Inter", sans-serif;
}
