:root {
  --red: #ff5252;
  --pink: #FFD8DC;
  --green:#00B96B;
  --orange:#FF8F51;
  --border:#d9e2ee;
  --search:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23BEBEBE' d='M1.9001 6.56966C1.9001 3.99073 3.99073 1.9001 6.56966 1.9001C9.14858 1.9001 11.2392 3.99073 11.2392 6.56966C11.2392 9.14858 9.14858 11.2392 6.56966 11.2392C3.99073 11.2392 1.9001 9.14858 1.9001 6.56966ZM6.56966 0.100098C2.99662 0.100098 0.100098 2.99662 0.100098 6.56966C0.100098 10.1427 2.99662 13.0392 6.56966 13.0392C7.91177 13.0392 9.15843 12.6305 10.192 11.9308L13.6567 15.3955C14.0081 15.747 14.578 15.747 14.9294 15.3955C15.2809 15.044 15.2809 14.4742 14.9294 14.1227L11.53 10.7233C12.472 9.59945 13.0392 8.15079 13.0392 6.56966C13.0392 2.99662 10.1427 0.100098 6.56966 0.100098Z'/%3e%3c/svg%3e") no-repeat left 8px center / 18px;
}
::selection {
  color: #fff;
  background: #5a5278;
}
a,
.btn-link {
  color: #5a5278;
}
.text-green,
.text-info,
.text-success,
.text-danger,
.text-primary,
.text-warning,
.mail-star.active,
.page-title {
  color: #5a5278 !important;
}
.badge-default {
  background: #3f3d56;
  color: #f1f1f1;
}
.loader {
  color: #5a5278;
}
.badge-primary {
  background-color: #5a5278;
}
.btn-primary {
  background: var(--red);
  color: #ffffff;
  /* border-color: #ffa600; */
}

.btn-primary:hover {
  background: #ff0000 !important;
}
.btn-info {
  background-color: #e8769f;
  border-color: #e8769f;
}
.nav-tabs .nav-link.active {
  border-color: #5a5278;
  color: #5a5278;
}
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 82, 120, 0.25);
}
.card,
.dropify-wrapper,
.btn-primary {
  border: 0;
}
.bg-primary,
.bg-info,
.bg-warning,
.btn-success {
  background-color: #5a5278 !important;
  border: transparent;
}
.bg-success,
.bg-danger,
.badge-success,
.progress-bar,
.btn-danger,
.btn-warning {
  background-color: #e8769f;
  border: transparent;
}
.btn-outline-primary {
  color: #5a5278;
  border-color: #5a5278;
}
.btn-outline-primary:hover {
  background-color: #2f2f2f;
  border-color: #2f2f2f;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before,
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  border-color: #2f2f2f;
  background-color: #2f2f2f;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #5a5278;
}
.selectgroup-input:checked + .selectgroup-button {
  border-color: #5a5278;
  color: #5a5278;
  background: rgba(90, 82, 120, 0.2);
}
.imagecheck-figure:before {
  color: #e8769f !important;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active,
.wizard > .actions a,
.wizard > .actions a:hover {
  background: #5a5278;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover {
  background: rgba(90, 82, 120, 0.2);
  color: #5a5278;
}
.table th {
  color: #2c2945;
}
.table.table-custom td,
.table.table-custom th {
  background: #f1f1f1;
}
.right_chat li.online .status {
  background: #5a5278;
}
.bg-blue,
.bg-azure,
.bg-indigo,
.bg-purple,
.bg-red,
.bg-orange {
  background-color: #5a5278 !important;
}
.bg-pink,
.bg-yellow,
.bg-lime,
.bg-green,
.bg-teal,
.bg-cyan {
  background-color: #e8769f !important;
}
.bg-light-blue,
.bg-light-azure,
.bg-light-indigo,
.bg-light-lime,
.bg-light-green,
.bg-light-teal {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.bg-light-pink,
.bg-light-red,
.bg-light-orange,
.bg-light-yellow,
.bg-light-cyan {
  background-color: rgba(232, 118, 159, 0.1);
  color: #e8769f;
}
.bg-light-purple {
  background-color: rgba(228, 189, 81, 0.1);
  color: #e4bd51;
}
.bg-light-gray {
  background-color: rgba(102, 106, 109, 0.1);
  color: #666a6d;
}
.avatar.avatar-blue {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.avatar.avatar-azure {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.avatar.avatar-indigo {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.avatar.avatar-purple {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.avatar.avatar-pink {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.avatar.avatar-red {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.avatar.avatar-orange {
  background-color: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.avatar.avatar-yellow {
  background-color: rgba(232, 118, 159, 0.1);
  color: #e8769f;
}
.avatar.avatar-lime {
  background-color: rgba(232, 118, 159, 0.1);
  color: #e8769f;
}
.avatar.avatar-green {
  background-color: rgba(232, 118, 159, 0.1);
  color: #e8769f;
}
.avatar.avatar-teal {
  background-color: rgba(232, 118, 159, 0.1);
  color: #e8769f;
}
.avatar.avatar-cyan {
  background-color: rgba(232, 118, 159, 0.1);
  color: #e8769f;
}
body {
  background-color: #f4f4f4;
  color: #2c2945;
}
.page-header .right .nav-pills .nav-link {
  color: #2c2945;
}
#header_top .brand-logo {
  background: #5a5278;
}
.header {
  background: #5a5278;
}
.header .nav-tabs .nav-link {
  color: #fff;
}
.header .nav-tabs .nav-link.active {
  border-color: #fff;
}
.header .dropdown-menu .dropdown-item:hover,
.header .dropdown-menu .dropdown-item.active {
  color: #5a5278;
}
/* .page .section-white,
.page .section-body {
  background: white;
} */
#header_top .nav-link {
  color: white;
}
.header_top.dark {
  background-color: #39374e !important;
}
.header_top.dark .nav-link {
  color: #f1f1f1 !important;
}
.card {
  background-color: #fff;
}
.card .card-options a {
  color: #5a5278;
}
.card .card-options a.btn {
  color: #fff;
}
.card.card-fullscreen {
  background-color: #fff !important;
}
.metismenu a {
  color: #2c2945;
}
.metismenu a:hover {
  color: #5a5278;
  border-color: #5a5278;
}
.metismenu .active > a {
  color: #5a5278;
  border-color: #5a5278;
}
.metismenu .active ul .active a {
  color: #5a5278;
  background: transparent;
}
.metismenu.grid > li.active > a,
.metismenu.grid > li > a:hover {
  background: rgba(90, 82, 120, 0.1);
  color: #5a5278;
}
.inbox .detail {
  background: #fff;
}
.file_folder a {
  background: #fff;
  border-color: #f1f1f1;
}
.auth .auth_left {
  background: #fff;
}
.gender_overview {
  background: #f1f1f1;
}
.table-calendar-link {
  background: #5a5278;
}
.table-calendar-link:hover {
  background: #39374e;
}
.table-calendar-link::before {
  background: #39374e;
}
.theme_div {
  background: #fff;
}
.ribbon .ribbon-box.green {
  background: #cedd7a;
}
.ribbon .ribbon-box.green::before {
  border-color: #cedd7a;
  border-right-color: transparent;
}
.ribbon .ribbon-box.orange {
  background: #5a5278;
}
.ribbon .ribbon-box.orange::before {
  border-color: #5a5278;
  border-right-color: transparent;
}
.ribbon .ribbon-box.pink {
  background: #e8769f;
}
.ribbon .ribbon-box.pink::before {
  border-color: #e8769f;
  border-right-color: transparent;
}
.ribbon .ribbon-box.indigo {
  background: #e4bd51;
}
.ribbon .ribbon-box.indigo::before {
  border-color: #e4bd51;
  border-right-color: transparent;
}
.text-orange {
  color: #e4bd51 !important;
}
.tag-info {
  background: #5a5278;
}
.tag-success,
.tag-green {
  background: #cedd7a;
}
.tag-orange {
  background: #e8769f;
}
.tag-danger {
  background: #e8769f;
}
.bg-success {
  background: #cedd7a !important;
}
.bg-danger {
  background-color: #e8769f !important;
}
.auth .auth_left {
  background: #2f2f2f;
}
.auth .auth_left .card {
  padding: 20px;
  right: -150px;
  z-index: 99999;
}

@media only screen and (max-width: 1024px) {
  .auth .auth_left .card {
    right: -50px;
  }
}
@media only screen and (max-width: 992px) {
  .auth .auth_left .card {
    right: auto;
  }
}
.top_dark {
  background-color: #39374e !important;
}
.sidebar_dark #left-sidebar {
  background-color: #39374e !important;
}
.sidebar_dark #header_top .nav-link {
  color: #f1f1f1 !important;
}
.sidebar_dark .sidebar .metismenu .active > a {
  color: #e4bd51;
}
.sidebar_dark .sidebar .metismenu .active ul .active a {
  color: #e4bd51;
}
.iconcolor #header_top .nav-link {
  color: #e4bd51 !important;
}
.gradient .custom-switch-input:checked ~ .custom-switch-indicator {
  background: linear-gradient(45deg, #5a5278, #e8769f) !important;
}
.gradient .metismenu.grid > li.active > a,
.gradient .metismenu.grid > li > a:hover {
  background: linear-gradient(45deg, #5a5278, #5a5278) !important;
  color: #fff;
  border: 0;
}
.gradient .btn-primary {
  background: linear-gradient(45deg, #2f2f2f, #e8769f) !important;
  border: 0;
}
.gradient .btn-dark {
  background: linear-gradient(45deg, #808488, #333537) !important;
}
.gradient .bg-success,
.gradient .bg-danger,
.gradient .badge-success,
.gradient .progress-bar,
.gradient .btn-danger {
  background: linear-gradient(45deg, #5a5278, #e8769f) !important;
}
.metismenu.grid > li > a {
  border-color: #5a5278;
}
