.job-card-container {
  display: flex;
  flex-grow: 1;
}

.job-card {
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.job-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #ffffff, #f1f1f1);
}

.icon-container {
  font-size: 30px;
  color: #ff4757;
  padding: 5px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.job-info {
  font-size: 17px;
  margin: 5px;
  color: #4b4b4b;
  text-align: center;
  font-weight: bold;
}

.job-number {
  font-size: 30px;
  font-weight: 700;
  color: #ff4757;
  text-align: center;
  margin: 0;
}
