.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
  background-color: #39374e !important;
  color: #babdbf !important;
  border-color: #39374e !important;
}

.nav-link-gdc-selected {
  font-weight: bold !important;
}

div.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
div.font-roboto {
  font-family: "Robot", sans-serif;
}
div.font-opensans {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
/* div.rtl{ */
div.rtl {
  direction: rtl !important;
  text-align: right;
}
.sticky-top {
  width: calc(100% - 280px);
  position: fixed;
}
.marginTop {
  margin-top: 7.2% !important;
}

.dropdown-item:hover {
  cursor: pointer;
}

.sidebar_text {
  font-size: 11px;
  margin-top: 5px;
}

/* .p-dialog-header {
/* .p-dialog-header {
  display: none !important;
} */

.p-dialog-header-icon {
  background-color: unset !important;
}

.p-column-filter-menu-button {
  background-color: unset !important;
}

/* .p-button {
  background: #f2f2f2 !important;
  color: black;
  margin-bottom: 1%;
} */


.dot {
  position: absolute;
  
  top: 8px;
  right: 8px;
  width: 6px;
  height: 6px;
  background-color: #ff5252; /* Change color as needed */
  border-radius: 50%;
}
.p-paginator-element {
  background: unset !important;
}

.p-paginator-element:hover {
  background: #f2f2f2;
  cursor: pointer;
}
.p-row-toggler {
  background-color: unset !important;
}

/* .p-column-header-content {
  justify-content: flex-start;
} */

.p-accordion-toggle-icon {
  color: black;
}

.p-dialog-header-icon {
  background-color: unset !important;
}

.p-tag {
  width: 55px;
  padding: 1px 3px;
  font-size: 0.5rem;
}

.p-text-sm {
  width: 50px;
}

.p-tag-value {
  font-size: 8px;
}

/* .p-dropdown {
  width: 160px;
} */

.p-dropdown-item{
  font-size: 12px;
  padding: 4px 10px;
}

.nav-link {
  margin-top: 5px;
}

.nav-link svg {
  height: 25px;
  width: 25px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #ff5252 !important;
}

.p-tabview-title {
  color: black;
}

.p-tabview-panels {
  padding: 0;
}

.p-tabview-nav {
  margin-bottom: 0;
}

.p-datatable {
  font-size: 14px; /* Adjust the font size as per your requirement */
}

/* Example: Reduce font size of table headers */
.p-datatable .p-datatable-thead th {
  font-size: 14px; /* Adjust the font size as per your requirement */
  padding: 6px 4px;
}
/* .p-datatable-thead {
  z-index: 0;
} */
.css-b62m3t-container>div+div{
  z-index: 44;
}


/* Example: Reduce font size of table cells */
.p-datatable .p-datatable-tbody td {
  font-size: 10px; /* Adjust the font size as per your requirement */
}

.p-datatable .p-datatable-tbody tr {
  height: 15px; /* Adjust the row height as per your requirement */
  line-height: 15px; /* Adjust the line height as per your requirement */
}

/* Example: Reduce padding of table cells */
.p-datatable .p-datatable-tbody td {
  padding: 4px; /* Adjust the padding as per your requirement */
  text-wrap: nowrap;
}

.p-inputtext,
.p-multiselect-label {
  padding: 4px;
  font-size: 12px;
}


.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0.3rem;
}
.p-tabview-panels .p-unselectable-text {
  margin: 10px 5px;
}
.p-tabview-panels .p-tabview-nav-link {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  padding: 5px 15px;
}
.p-tabview-panels .p-tabview-selected .p-tabview-nav-link {
  background: #ff5252;
}

.p-button{
  /* background: #fe4550; */
  border: none !important;
}


#formFile::file-selector-button, #formFileLines::file-selector-button{
  background-color: #fe4550;
  border: none;
  color: #ffffff;
  font-size: 14px;
  padding: 4px 10px;
  margin-right: 5%;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.p-inputtextarea {
  min-height: 31px;
  margin-top: 5px;
  height: 31px;
}

.p-column-title {
  width: max-content;
  text-wrap: nowrap;
}

.p-sortable-column-icon {
  width: 12px;
  height: 12px;
}

.react-flow__attribution {
  display: none;
}

.p-datepicker-trigger{
  border-radius: 0 !important;
}

.p-calendar{
height: 30px;
padding: 2px;
}

.p-calendar button{
  background: #fe4550;
}

.p-calendar input:hover{
 cursor: pointer !important;
}


.p-datepicker-trigger{
  width: 30px !important;
  height: 100% !important;
}

.card-body{
  padding: 5px 20px;
}


@media (max-width: 769px) {
  .card {
    width: 100% !important ;
  }

  .sidebar_text{
    display: none;
  }

  #header_top .nav-link{
    padding: 8px 4px;
  }
}




.p-column-title{
  font-size: 12px;
}
.jobs-tab > .p-datatable .p-datatable-tbody td>svg{
  width: 20px;
  height: 17px;
  padding: 0 !important;
}

.jobs-tab > .p-datatable .p-datatable-tbody td>button{
   width: 25px;
    height: 15px;
  padding: 5px !important;
  margin: 0 5px !important;
  margin-top: 2px !important;
  position: relative;
  top: 2px;
  right: 5px;
}

.p-datatable .p-datatable-tbody td>button:focus{
  outline: none !important;
  box-shadow: none !important;
}

.jobs-tab >.p-datatable .p-datatable-tbody button>svg{
  height: 15px;
}

.jobs-tab > .p-datatable .p-datatable-tbody td>a>svg{
  height: 22px;
  width: 25px;
}


.p-checkbox {
  width: 12px;
  height: 12px;
  border-radius: 0 !important;
  margin-bottom: 3px;
}

.p-checkbox .p-checkbox-box{
  width: 12px !important;
  height: 12px !important;
  border-radius: 0 !important;
}


#logs .p-datatable .p-datatable-tbody td {
  text-wrap: wrap;
}

#orderDate .p-calendar{
  height: 39px !important;
  padding: 0 !important;
}

.p-tabview-title{
  font-size: 12px;
  padding: 0 !important;
}
 .p-tabview-nav-link {
  padding: 0.7rem;
  font-size: 1rem;
}



 .p-tabview-nav-link  .p-tabview-title {
  font-size: 14px !important;
}
.form-label{
  font-size: 12px !important;
}

#reports .p-calendar{
  height: 33px !important;
  padding: 0 !important;
}